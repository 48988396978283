import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { faCaretDown, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

const birthday = '08/07/1987 07:35:00';

export default class AboutEs extends Component {
  constructor() {
    super();
    this.state = { checked: false, ageMS: 0 };
    this.enableFullProfile = this.enableFullProfile.bind(this);
    this.renderFull = this.renderFull.bind(this);
    this.calculateAge = this.calculateAge.bind(this);
    this.calculateMilisecondsAge = this.calculateMilisecondsAge.bind(this);
  }

  componentDidMount() {
    this.timer = setInterval(() => this.calculateMilisecondsAge(), 50);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  calculateMilisecondsAge() {
    const ageMS = new Date() - new Date(birthday);

    this.setState({
      ageMS
    });
  }

  calculateAge() {
    const ageMS = this.state.ageMS;
    const age = new Date();
    age.setTime(ageMS);
    const ageYear = age.getFullYear() - 1970;

    return ageYear;
  }

  enableFullProfile(event) {
    event.preventDefault();
    this.setState({ checked: !this.state.checked });
  }

  renderButton() {
    if (this.state.checked) {
      return null;
    }

    return (
      <button className="btn-read-more text-slate-800" onClick={this.enableFullProfile}>
        Leer completo <FontAwesomeIcon className="ml-1 text-slate-400" icon={faCaretDown} />
      </button>
    );
  }

  renderFull() {
    return (
      <CSSTransition in={this.state.checked} timeout={200} classNames="my-node" unmountOnExit>
        <>
          <p>Estudié Ing. en Electrónica y Comunicaciones, una maestría en Gestión de Proyectos y una maestríaen Gestión de Tecnología (<a href="http://www.uc3m.es/" rel="noreferrer" title="Charles III University, Madrid - Spain" target="_blank">UC3M</a>). También estudié un posgrado en Educación Superior. Actualmente estudio una Maestría en Desarrollo de Aplicaciones y Servicios Web (<a href="https://www.universidadviu.com/es/" title="Universidad Internacional de Valencia. Valencia, España" target="_blank">VIU</a>), a ser completada en 2024.</p>

          <p className="text-sm">Tengo {this.calculateAge()} años... o siendo precisos: <em>{this.state.ageMS} milisegundos</em> de edad ⏲. Nací y crecí en Ciudad de Panamá. También hablo inglés (B2) e italiano (C1).</p>

          <h2 className="text-lg font-semibold mb-3">Experiencia</h2>

          <p>Mi experiencia se enfoca en el Desarrollo e Ingeniería de Software. Soy un desarrollador web full-stack y un desarrollador móvil, pero me inclino más por el desarrollo de front-end e interfaces (UI/UX) a nivel de software.</p>

          <p>He trabajado en la industria del software desde 2008, mayormente para compañías basadas en California, Estados Unidos. En la actualidad, soy un contratista de Ingeniería de Software.</p>

          <h3 className="text-md mb-3">Otros intereses</h3>

          <ul className="text-sm list-disc ml-3">
            <li>Creo en las tecnologías libres y abiertas.</li>
            <li>Me encanta viajar 🚂, la música 🎶 y la <Link to="/photos">fotografía</Link> 📸.</li>
            <li>Mantengo una fuerte convicción en los pensamientos e ideologías ágiles, Lean y de Producto.</li>
          </ul>
        </>
      </CSSTransition>
    );
  }

  render() {
    return (
      <div className="text-slate-300">
        <p className="text-lg">Me especializo en el diseño, arquitectura y desarrollo de aplicaciones para la web y dispositivos móviles. Tengo vasta experiencia en gestión de proyectos de software ágiles.</p>

        <p className="text-sm">Me puedes seguir en Twitter <a href="https://www.twitter.com/demogar" rel="noreferrer">@demogar</a>, ver mi perfil de <a href="https://www.github.com/demogar">GitHub</a> o en <a href="https://www.gitlab.com/demogar">GitLab</a> y mi perfil profesional de <a href="http://linkedin.com/in/demogar">LinkedIn</a>.</p>

        <h2 className={`text-lg font-semibold mb-3 ${this.state.checked ? "" : "gradient-text-read-more"}`}>Acerca</h2>

        {this.renderFull()}

        <div className="switcher-container mb-5">
          {this.renderButton()}
        </div>

        <Link to="/" className="btn-read-more btn-language text-slate-800">
          <FontAwesomeIcon icon={faLanguage} /> Read English version
        </Link>
      </div>
    );
  }
}
